import { ComputeEngine } from "@cortex-js/compute-engine";

export const latexToMathJson = (latex: string) => {
  const ce = new ComputeEngine();
  if (latex) {
    const rawMath = ce.parse(latex);
    let rawMathJson = rawMath.json;
    const expandMathJson = ce.box(["Expand", rawMath]).evaluate().json;

    if (expandMathJson !== "False") {
      rawMathJson = expandMathJson;
    }

    if (rawMath.isValid) {
      return rawMathJson;
    }

    return null;
  }
  return null;
};
