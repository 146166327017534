import React, { useCallback, useEffect, useRef, useState } from "react";
import MathView, { MathViewRef } from "react-math-view";
import { latexToMathJson } from "../utils/ComputeEngine";

const CUSTOM_KEYBOARD_LAYER = {
  "high1-1": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "\\overline{\\placeholder{}}" },
        { class: "small", latex: "\\angle" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\placeholder{}^{\\circ}" },
        { class: "small", latex: "\\pm" },
        { class: "small", latex: "\\omega" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "high1-2": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "\\subset" },
        { class: "small", latex: "\\subseteq" },
        { class: "small", latex: "\\supset" },
        { class: "small", latex: "\\supseteq" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "\\in" },
        { class: "small", latex: "\\notin" },
        { class: "small", latex: "\\cap" },
        { class: "small", latex: "\\cup" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "\\overline{\\placeholder{}}" },
        { class: "small", latex: "\\varnothing" },
        { class: "small", latex: "\\nsubset" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\placeholder{}^{\\circ}" },
        { class: "small", latex: "\\pm" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "high2-1": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "\\sin" },
        { class: "small", latex: "\\cos" },
        { class: "small", latex: "\\tan" },
        { class: "small", latex: "\\theta" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\placeholder{}^{\\circ}" },
        { class: "small", latex: "\\log_{\\placeholder{}}\\placeholder{}" },
        { class: "small", latex: "\\log\\placeholder{}" },
        { class: "small", latex: "\\sum_\\placeholder{}^\\placeholder{}" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "high2-2": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "\\cup" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "\\placeholder{}^{\\circ}" },
        { class: "small", latex: "^{\\prime}" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "\\int" },
        { class: "small", latex: "\\int_\\placeholder{}^\\placeholder{}" },
        { class: "small", latex: "\\sum_\\placeholder{}^\\placeholder{}" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        {
          class: "small",
          latex: "\\lim_{\\placeholder{} \\rightarrow \\placeholder{}}",
        },
        { class: "small", latex: "\\infty" },
        { class: "small", latex: "f\\left(x\\right)" },
        { class: "small", latex: "\\log_{\\placeholder{}}\\placeholder{}" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "high3-1": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "\\cap" },
        { class: "small", latex: "\\cup" },
        { class: "small", latex: "\\overline" },
        { class: "small", latex: "!" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\sum_\\placeholder{}^\\placeholder{}" },
        { class: "small", latex: "_\\placeholder{}\\Pi_\\placeholder{}" },
        { class: "small", latex: "_\\placeholder{}H_\\placeholder{}" },
        { class: "small", latex: "_\\placeholder{}C_\\placeholder{}" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "high3-2": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "\\sin" },
        { class: "small", latex: "\\cos" },
        { class: "small", latex: "\\tan" },
        { class: "small", latex: "\\theta" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "\\sec" },
        { class: "small", latex: "\\csc" },
        { class: "small", latex: "\\cot" },
        { class: "small", latex: "\\placeholder{}^{\\circ}" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        {
          class: "small",
          latex: "\\lim_{\\placeholder{} \\rightarrow \\placeholder{}}",
        },
        { class: "small", latex: "\\infty" },
        { class: "small", latex: "\\int" },
        { class: "small", latex: "\\int_\\placeholder{}^\\placeholder{}" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\sum_\\placeholder{}^\\placeholder{}" },
        { class: "small", latex: "^{\\prime}" },
        {
          class: "small",
          latex: "\\frac{\\partial \\placeholder{}}{\\partial \\placeholder{}}",
        },
        { class: "small", latex: "\\overline" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "high3-3": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "\\sin" },
        { class: "small", latex: "\\cos" },
        { class: "small", latex: "\\tan" },
        { class: "small", latex: "\\theta" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "\\sec" },
        { class: "small", latex: "\\csc" },
        { class: "small", latex: "\\cot" },
        { class: "small", latex: "\\placeholder{}^{\\circ}" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "\\perp" },
        { class: "small", latex: "//" },
        { class: "small", latex: "\\overrightarrow" },
        { class: "small", latex: "\\int_\\placeholder{}^\\placeholder{}" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "^{\\prime}" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "middle1-1": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\therefore" },
        { class: "small", latex: "\\bigcirc" },
        { class: "small", latex: "\\triangle" },
        { class: "small", latex: "\\square" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "middle1-2": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "\\overleftrightarrow{\\placeholder{}}" },
        { class: "small", latex: "\\overrightarrow{\\placeholder{}}" },
        { class: "small", latex: "\\overline{\\placeholder{}}" },
        { class: "small", latex: "\\overset{\\frown}{\\placeholder{}}" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "\\perp" },
        { class: "small", latex: "//" },
        { class: "small", latex: "\\angle" },
        { class: "small", latex: "\\placeholder{}^{\\circ}" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\rightarrow" },
        { class: "small", latex: "\\bigcirc" },
        { class: "small", latex: "\\triangle" },
        { class: "small", latex: "\\square" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "middle2-1": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\dot{\\placeholder{}}" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "middle2-2": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "\\equiv" },
        { class: "small", latex: "\\sim" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "\\overleftrightarrow{\\placeholder{}}" },
        { class: "small", latex: "\\overrightarrow{\\placeholder{}}" },
        { class: "small", latex: "\\overline{\\placeholder{}}" },
        { class: "small", latex: "\\overset{\\frown}{\\placeholder{}}" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "\\perp" },
        { class: "small", latex: "//" },
        { class: "small", latex: "\\angle" },
        { class: "small", latex: "\\placeholder{}^{\\circ}" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\rightarrow" },
        { class: "small", latex: "\\bigcirc" },
        { class: "small", latex: "\\triangle" },
        { class: "small", latex: "\\square" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "middle3-1": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\pm" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  "middle3-2": {
    style: "",
    rows: [
      [
        { class: "small", latex: "\\pi" },
        { class: "small", latex: ":" },
        { class: "small", latex: "|" },
        { class: "small", latex: "(" },
        { class: "small", latex: ")" },
        { class: "small", latex: "[" },
        { class: "small", latex: "]" },
        { class: "small", latex: "\\{" },
        { class: "small", latex: "\\}" },
        { class: "small", latex: "\\geq" },
        { class: "small", latex: "\\sin" },
        { class: "small", latex: "\\cos" },
        { class: "small", latex: "\\tan" },
        { class: "small", latex: "\\theta" },
      ],
      [
        { class: "small", latex: "\\alpha" },
        { class: "small", latex: "a" },
        { class: "small", latex: "x" },
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "\\sqrt" },
        { class: "small", latex: "\\sqrt[\\placeholder{}]{\\placeholder{}}" },
        { class: "small", latex: "\\leq" },
        { class: "small", latex: "\\overleftrightarrow{\\placeholder{}}" },
        { class: "small", latex: "\\overrightarrow{\\placeholder{}}" },
        { class: "small", latex: "\\overline{\\placeholder{}}" },
        { class: "small", latex: "\\overset{\\frown}{\\placeholder{}}" },
      ],
      [
        { class: "small", latex: "\\beta" },
        { class: "small", latex: "b" },
        { class: "small", latex: "y" },
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "\\div" },
        { class: "small", latex: "#@^{#?}" },
        { class: "small", latex: "\\placeholder{}_{\\placeholder{}}" },
        { class: "small", latex: ">" },
        { class: "small", latex: "\\rightarrow" },
        { class: "small", latex: "\\bigcirc" },
        { class: "small", latex: "\\triangle" },
        { class: "small", latex: "\\square" },
      ],
      [
        { class: "small", latex: "\\gamma" },
        { class: "small", latex: "c" },
        { class: "small", latex: "z" },
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "+" },
        { class: "small", latex: "-" },
        { class: "small", latex: "\\times" },
        { class: "small", latex: "<" },
        { class: "small", latex: "\\angle" },
        { class: "small", latex: "\\placeholder{}^{\\circ}" },
        { class: "small", latex: "\\equiv" },
        { class: "small", latex: "\\sim" },
      ],
      [
        { class: "small", latex: "\\delta" },
        { class: "small", latex: "d" },
        { class: "small", latex: "n" },
        { class: "small", latex: "," },
        { class: "small", latex: "0" },
        { class: "small", latex: "." },
        { class: "small", latex: "\\%" },
        { key: "O", label: "<i style='color:blue'>O</i>" },
        { key: "X", label: "<i style='color:red'>X</i>" },
        { class: "small", latex: "=" },
        { class: "small", latex: "\\neq" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
  unit: {
    style: "",
    rows: [
      [
        { class: "small", latex: "7" },
        { class: "small", latex: "8" },
        { class: "small", latex: "9" },
        { class: "small", latex: "mm" },
        { class: "small", latex: "mm^{2}" },
        { class: "small", latex: "mm^{3}" },
        { class: "small", latex: "mg" },
        { class: "small", latex: "m/s" },
        { class: "small", latex: "^{\\circ}" },
        { class: "small", latex: "초" },
        { class: "small", latex: "초속" },
        { class: "small", latex: "가지" },
        { class: "small", latex: "살" },
        { class: "small", latex: "자루" },
        { class: "small", latex: "편" },
      ],
      [
        { class: "small", latex: "4" },
        { class: "small", latex: "5" },
        { class: "small", latex: "6" },
        { class: "small", latex: "cm" },
        { class: "small", latex: "cm^{2}" },
        { class: "small", latex: "cm^{3}" },
        { class: "small", latex: "g" },
        { class: "small", latex: "km/h" },
        { class: "small", latex: "^{\\circ}\\mathrm{C}" },
        { class: "small", latex: "분" },
        { class: "small", latex: "분속" },
        { class: "small", latex: "개" },
        { class: "small", latex: "세" },
        { class: "small", latex: "점" },
        { class: "small", latex: "호" },
      ],
      [
        { class: "small", latex: "1" },
        { class: "small", latex: "2" },
        { class: "small", latex: "3" },
        { class: "small", latex: "mm" },
        { class: "small", latex: "mm^{2}" },
        { class: "small", latex: "mm^{3}" },
        { class: "small", latex: "kg" },
        { class: "small", latex: "ml" },
        { class: "small", latex: "^{\\circ}\\mathrm{F}" },
        { class: "small", latex: "시" },
        { class: "small", latex: "시속" },
        { class: "small", latex: "모둠" },
        { class: "small", latex: "송이" },
        { class: "small", latex: "조" },
        { class: "small", latex: "회" },
      ],
      [
        { class: "small", latex: "." },
        { class: "small", latex: "0" },
        { class: "small", latex: "\\frac{\\placeholder{}}{\\placeholder{}}" },
        { class: "small", latex: "km" },
        { class: "small", latex: "km^{2}" },
        { class: "small", latex: "km^{3}" },
        { class: "small", latex: "t" },
        { class: "small", latex: "l" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "" },
        { class: "small", latex: "명" },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-left' /></svg>",
          command: ["performWithFeedback", "moveToPreviousChar"],
        },
        {
          class: "action",
          label: "<svg><use xlink:href='#svg-arrow-right' /></svg>",
          command: ["performWithFeedback", "moveToNextChar"],
        },
        {
          class: "action font-glyph bottom right",
          label: "&#x232b;",
          command: ["performWithFeedback", "deleteBackward"],
        },
      ],
    ],
  },
};

const CUSTOM_KEYBOARD = {
  "abc-keyboard": {
    label: "영문",
    tooltip: "영문 키보드입니다",
    layer: "lower-roman",
  },
  "common-keyboard": {
    label: "수식",
    tooltip: "수식 키보드입니다",
    layer: "common",
  },
  "middle1-1-keyboard": {
    label: "중1-1",
    tooltip: "중1-1 키보드입니다",
    layer: "middle1-1",
  },
  "middle1-2-keyboard": {
    label: "중1-2",
    tooltip: "중1-2 키보드입니다",
    layer: "middle1-2",
  },
  "middle2-1-keyboard": {
    label: "중2-1",
    tooltip: "중2-1 키보드입니다",
    layer: "middle2-1",
  },
  "middle2-2-keyboard": {
    label: "중2-2",
    tooltip: "중2-2 키보드입니다",
    layer: "middle2-2",
  },
  "middle3-1-keyboard": {
    label: "중3-1",
    tooltip: "중3-1 키보드입니다",
    layer: "middle3-1",
  },
  "middle3-2-keyboard": {
    label: "중3-2",
    tooltip: "중3-2 키보드입니다",
    layer: "middle3-2",
  },
  "high1-1-keyboard": {
    label: "수학(상)",
    tooltip: "수학(상) 키보드입니다",
    layer: "high1-1",
  },
  "high1-2-keyboard": {
    label: "수학(하)",
    tooltip: "수학(하) 키보드입니다",
    layer: "high1-2",
  },
  "high2-1-keyboard": {
    label: "수학1",
    tooltip: "수학1 키보드입니다",
    layer: "high2-1",
  },
  "high2-2-keyboard": {
    label: "수학2",
    tooltip: "수학2 키보드입니다",
    layer: "high2-2",
  },
  "high3-1-keyboard": {
    label: "확률과통계",
    tooltip: "확률과통계 키보드입니다",
    layer: "high3-1",
  },
  "high3-2-keyboard": {
    label: "미적분",
    tooltip: "미적분 키보드입니다",
    layer: "high3-2",
  },
  "high3-3-keyboard": {
    label: "기하",
    tooltip: "기하 키보드입니다",
    layer: "high3-3",
  },
  "unit-keyboard": {
    label: "단위",
    tooltip: "단위 키보드입니다",
    layer: "unit",
  },
};

const EquationInput = (props: any) => {
  const [value, setValue] = useState(props.inputValue);
  const [mathJson, setMathJson] = useState(latexToMathJson(value));
  const onChange = useCallback(
    (e: React.SyntheticEvent<any, any>) => {
      setValue(e.currentTarget.getValue());
      props.onChange && props.onChange(e);
    },
    [props.onChange]
  );
  const copyText = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      alert("클립보드에 복사되었습니다");
    } catch (error) {
      alert("복사 실패!");
    }
  };

  useEffect(() => {
    setMathJson(latexToMathJson(value));
  }, [value]);
  useEffect(() => {
    setValue(props.inputValue);
  }, [props.inputValue]);

  useEffect(() => {
    ref.current?.setOptions({
      customVirtualKeyboardLayers: CUSTOM_KEYBOARD_LAYER,
      customVirtualKeyboards: CUSTOM_KEYBOARD,
      virtualKeyboards:
        "middle1-1-keyboard  middle1-2-keyboard  middle2-1-keyboard  middle2-2-keyboard  middle3-1-keyboard  middle3-2-keyboard  high1-1-keyboard  high1-2-keyboard  high2-1-keyboard  high2-2-keyboard  high3-1-keyboard  high3-2-keyboard  high3-3-keyboard abc-keyboard unit-keyboard",
      keypressSound: null,
      plonkSound: null,
    });
  });

  const ref = useRef<MathViewRef>(null);

  return (
    <div className="equation-input">
      <MathView {...props} ref={ref} value={value} onChange={onChange} />
      <div className="result-box">
        <button onClick={() => copyText(value)}>Latex : {value}</button>
        <button onClick={() => copyText(JSON.stringify(mathJson))}>
          MathJson : {JSON.stringify(mathJson)}
        </button>
      </div>
    </div>
  );
};

export default EquationInput;
