import React from "react";
import "./App.css";
import EquationEditor from "./pages/EquationEditor";
import logo from "./img/almighty-logo.svg";
import santaImg from "./img/santa_and_deer.png";
import snowManImg from "./img/snowman.png";
import snowFlakeImg from "./img/snowflake.png";
import gingerBreadImg from "./img/ginger_breadman.png";

function App() {
  return (
    <div className="App">
      <div className="logo">
        <img src={gingerBreadImg} className="ginger-bread-img" />
        <img src={logo} className="logo-img" />
        <img src={snowManImg} className="snowman-img" />
        <img src={snowFlakeImg} className="snowflake-img" />
        <img src={snowFlakeImg} className="snow-img" />
      </div>
      <div className="content-wrapper">
        <EquationEditor />
      </div>
      <img src={santaImg} className="santa-img" />
    </div>
  );
}

export default App;
