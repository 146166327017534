import CSVDownloader from "components/CSVDownloader";
import CSVReader from "components/CSVReader";
import React, { useEffect } from "react";
import EquationInput from "../components/EquationInput";
import { latexToMathJson } from "../utils/ComputeEngine";
import deleteIcon from "../img/delete.svg";

function EquationEditor() {
  const [csvLatexList, setCsvLatexList] = React.useState([]);
  const [latexList, setLatexList] = React.useState([""]);

  useEffect(() => {
    const newLatexList = [...latexList, ...csvLatexList];
    setLatexList(newLatexList);
  }, [csvLatexList]);

  const updateLatex = (latex: string, index: number) => {
    const newLatexList = [...latexList];
    newLatexList[index] = latex;
    setLatexList(newLatexList);
  };

  const addLatex = (latex: string) => {
    setLatexList([...latexList, latex]);
  };
  const removeLatex = (index: number) => {
    const newArr = [...latexList];
    newArr.splice(index, 1);
    setLatexList(newArr);
  };

  return (
    <div className="editor-content">
      <CSVReader setValue={setCsvLatexList} targetColumn={"latex"}></CSVReader>
      <div className="editor-input-container">
        {latexList.map((latex, index) => (
          <div key={index}>
            <EquationInput
              virtualKeyboardMode="onfocus"
              inputValue={latex}
              onCommit={(e: any) => updateLatex(e.target.value, index)}
            ></EquationInput>
            <button className="delete-btn" onClick={() => removeLatex(index)}>
              {" "}
              <img src={deleteIcon}></img>
            </button>
          </div>
        ))}
        <button className="add-input-button" onClick={() => addLatex("")}>
          +
        </button>
      </div>
      <div className="editor-btn-box">
        <button>Save</button>
        <CSVDownloader
          columns={["latex", "mathJson"]}
          targetData={[latexList, latexList.map((x) => latexToMathJson(x))]}
        ></CSVDownloader>
      </div>
    </div>
  );
}

export default EquationEditor;
