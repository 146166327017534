import React from "react";

import { useCSVDownloader } from "react-papaparse";

export default function CSVDownloader({ columns, targetData }: any) {
  const { CSVDownloader, Type } = useCSVDownloader();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const transposedData = targetData[0].map((_, colIndex) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    targetData.map((row) => row[colIndex])
  );

  const processedData = transposedData.map((x: any) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.assign(...columns.map((k, i) => ({ [k]: JSON.stringify(x[i]) })))
  );

  return (
    <CSVDownloader
      type={Type.Button}
      filename={"filename"}
      bom={true}
      config={{
        delimiter: ";",
      }}
      data={processedData}
    >
      Download
    </CSVDownloader>
  );
}
